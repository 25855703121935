import { DateTime } from 'luxon';
import { FC } from 'react';
import MiddleDot from '~/icons/MiddleDot';
import { Tag } from '~/types';

import { Link } from '@remix-run/react';

import styles from './ArticleTopicsAndDate.module.scss';

interface Props {
  tags?: Tag[];
  publishedAt: string;
}

const ArticleTopicsAndDate: FC<Props> = ({ tags, publishedAt }) => {
  const relativeTime = DateTime.fromISO(publishedAt, {
    zone: 'utc',
  }).toRelative();

  const mainTag  = tags?.find(
    (tag) => tag.type?.toLowerCase() === 'main_category',
  );
  const mainSubTag = tags?.find(
    (tag) => tag.type?.toLowerCase() === 'subcategory',
  );

  return (
    <div className={styles.box}>
      {(mainTag || mainSubTag) && (
        <div className={styles.tags}>
          {mainTag && (
            <Link className={styles.tagLink} to={`/discover/${mainTag.slug}`}>
              {mainTag.title}
            </Link>
          )}
          {mainTag && mainSubTag && ' / '}
          {mainSubTag && (
            <Link
              className={styles.tagLink}
              to={`/discover/${mainSubTag.slug}`}
            >
              {mainSubTag.title}
            </Link>
          )}
          <MiddleDot />
        </div>
      )}
      <div className={styles.date}>{relativeTime}</div>
    </div>
  );
};

export default ArticleTopicsAndDate;
