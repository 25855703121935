import { FC } from 'react';
import { Reference } from '~/types';

import styles from './ArticleReferences.module.scss';

interface Props {
  references: Reference[];
}

const ArticleReferences: FC<Props> = ({ references }) => {
  return <div className={styles.title}>{references.length} references</div>;
};

export default ArticleReferences;
