const MiddleDot = () => {
  return (
    <svg
      width='5'
      height='5'
      viewBox='0 0 5 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.524 4.16C2.03333 4.16 1.61733 3.98933 1.276 3.648C0.945333 3.30667 0.78 2.88533 0.78 2.384C0.78 1.91467 0.945333 1.50933 1.276 1.168C1.61733 0.815999 2.03333 0.639999 2.524 0.639999C3.01467 0.639999 3.43067 0.815999 3.772 1.168C4.11333 1.50933 4.284 1.91467 4.284 2.384C4.284 2.88533 4.11333 3.30667 3.772 3.648C3.43067 3.98933 3.01467 4.16 2.524 4.16Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default MiddleDot;
