import { FC } from 'react';
import ArticleListHighlights from '~/components/Article/ArticleListHighlights/ArticleListHighlights';
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage';
import { useUser } from '~/context/UserContext';
import { Article } from '~/types';

import { Link } from '@remix-run/react';

import AdminArticleInfo from '../AdminArticleInfo/AdminArticleInfo';
import ArticleActions from '../ArticleActions/ArticleActions';
import ArticleReferences from '../ArticleReferences/ArticleReferences';
import ArticleTopicsAndDate from '../ArticleTopicsAndDate/ArticleTopicsAndDate';
import ReportBlock from '../ReportBlock/ReportBlock';
import TagStats from '../TagStats/TagStats';
import listStyles from './ArticleListCardBullets.module.scss';

interface Props {
  article: Article;
}

const ArticleListCardBullets: FC<Props> = ({ article }) => {
  const { user } = useUser();

  return (
    <div className={listStyles.card}>
      <article className={listStyles.cardArticle}>
        <div className={listStyles.imageHolder}>
          <Link to={`/article/${article.slug}`} aria-label={article.title}>
            <ResponsiveImage
              mediaData={article.media}
              alt={article.title}
              className={listStyles.img}
            />
          </Link>
          <ReportBlock article_id={article.id} />
        </div>
        <div className={listStyles.cardBottom}>
          {!!user?.is_admin && <AdminArticleInfo article={article} />}
          <div className={listStyles.dateHolder}>
            <ArticleTopicsAndDate
              tags={article.tags}
              publishedAt={article.published_at}
            />
          </div>
          <Link className={listStyles.link} to={`/article/${article.slug}`}>
            <h2 className={listStyles.heading}>{article.title}</h2>
          </Link>
          {!!article.references?.length && (
            <div className={listStyles.referencesHolder}>
              <ArticleReferences references={article.references} />
            </div>
          )}
          {article.highlights && article.highlights.length > 0 && (
            <div className={listStyles.highliths}>
              <ArticleListHighlights list={article.highlights} />
            </div>
          )}
          {!!article.tag_stats && user?.is_admin && (
            <TagStats
              stats={article.tag_stats}
              version={article.auto_generation_version}
              tags={article.tags}
            />
          )}
          {user?.is_admin && (
            <a
              className={listStyles.adminLink}
              target='blank'
              href={`https://api.bp.news/admin/articles/${article.id}/edit`}
            >
              Admin
            </a>
          )}
          <div className={listStyles.actionsHolder}>
            <ArticleActions
              articleId={article.id}
              votesMeta={article.votes_meta}
              commentsCount={article.comments.length ?? 0}
              slug={article.slug}
              tagStats={article.tag_stats}
              tags={article.tags}
            />
          </div>
        </div>
      </article>
    </div>
  );
};

export default ArticleListCardBullets;
