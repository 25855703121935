import { FC } from 'react';
import { useViewMode } from '~/context/ViewModeContext';
import { Article } from '~/types';

import ArticleListCardBullets from './ArticleListCardBullets';
import ArticleListCardDescription from './ArticleListCardDescription';

interface Props {
  article: Article;
}

const ArticleListCard: FC<Props> = ({ article }) => {
  const { viewMode } = useViewMode();

  return (
    <>
      {viewMode === 'bullets' ? (
        <ArticleListCardBullets article={article} />
      ) : (
        <ArticleListCardDescription article={article} />
      )}
    </>
  );
};

export default ArticleListCard;
